@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: Play, Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

body {
  padding: 0;
  margin: 0 0;
  display: flex;
  flex-flow: column nowrap;
  /* justify-content: center; */
  align-items: center;
  width: 100vw;
  background-color: #333;
  height: 100vh;
  max-height: 100vh;
  margin: 0;
}

.reference {
  color: rgb(195, 195, 195);
  font-size: 1.5rem;
  margin: 5px;
  border: 5px solid red;
  padding: 5px;
  text-align: center;
}

.reference a {
  color: red;
  font-size: 1.5rem;
}

.reference a:hover {
  text-decoration: underline;
}

.game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* margin-top: 170px; */
  /* margin-top: 280px; */
  margin-top: 12%;
  margin-top: auto;
  margin-bottom: auto;
}

.container {
  display: flex;
  flex-direction: column;
  min-width: 720px;
  width: 720px;
  max-width: 720px;
  min-height: 604px;
  min-height: 761px;
  height: 761px;
  max-height: 761px;
  background-color: rgb(195, 195, 195);
  padding: 10px;
  padding-bottom: 28px;
  border-radius: 5px;
  box-shadow: 0 0 10px 2px;
  text-align: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 566px;
}

.btn-grid {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  margin-top: auto;
}

.btn {
  background-color: rgb(95, 95, 95);
  border: 1px solid;
  border-radius: 5px;
  padding: 10px 10px;
  color: rgb(210, 210, 210);
  outline: none;
  width: 100%;
  min-height: 75px;
  opacity: 0;
  transition: opacity 1s ease-out;
}

/* .btn:hover {
  background-color: rgb(195, 195, 195);
  border-color: black;
  color: black;
  cursor: pointer;
  transition: 0.5s;
} */

/* Introductory page */

#intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  text-decoration: none;
}

#text {
  font-size: 19px !important;
  margin-top: 20px;
  margin-bottom: 26px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  min-width: 481px;
  max-width: 481px;
  /* width: 85%; */
  min-height: 25px;
  /* height: 50px; */
  max-height: 95px;
}

#image {
  overflow: hidden;
  max-width: 700px;
  min-height: 416px;

  /* transform: scale(1.05) translate(0, 7px); */
}

  #image img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px;
    margin-top: 16px;
    /* max-width: 875px; */
    max-width: 566px;
    min-height: 400px;
    max-height: 400px;
    object-fit: cover;
    text-align: center;
    overflow: hidden;
    filter: grayscale(30%);

    /* -webkit-mask-image: url('data:image/svg+xml;utf8,<svg preserveAspectRatio="xMidYMid meet" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 120 120" xml:space="preserve"><style type="text/css">.blur{filter:url(%23softedge);}</style><filter id="softedge"><feGaussianBlur stdDeviation="3.5"></feGaussianBlur></filter><rect x="10" y="11" width="100" height="100" class="blur"/></svg>');
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center; */

    opacity: 1;
    transition: opacity 1s ease-out;
    
  }

.vote-and-countdown {
  max-width: 566px;
  max-height: 186px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75); /* Adjust color and opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure it's above everything else */
}

.get-ready {
  font-size: 2.5em;
  color: white; /* Ensure text is visible on overlay */
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  /* position the div in center */
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#continue {
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Lato', sans-serif; */
  /* font-size: 34px; */
  /* font-size: 28px; */
  font-size: 27px;
  font-weight: 200;
  margin-top:28px;
  margin-bottom:24px;
}

#the-end {
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Lato', sans-serif; */
  /* font-size: 34px; */
  /* font-size: 28px; */
  font-size: 25px;
  font-weight: 200;
  margin-top:24px;
  margin-bottom:24px;
}








/* CSS animations */

/* Fade-in animation */

/* @keyframes fadeInAnimationImage {
  to {
    opacity: 1;
  }
} */

.always-visible {
  opacity: 1 !important; /* Ensures image remains visible */
}

.hidden {
  visibility: hidden !important;
  opacity: 0 !important;
}

.visible {
  visibility: visible;
}

@keyframes fadeInAnimationImage {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Apply animations */
/* .fade-in, .fade-in-alt {
  animation: fadeInAnimationImage 2.5s ease-out forwards;
} */

@keyframes fadeInAnimationButtons {
  to {
    opacity: 1;
  }
}

/* Apply animations */
/* .fade-in-buttons {
  animation: fadeInAnimationButtons 2.5s ease-out forwards;
} */

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fadeIn {
  animation: fadeIn 2s ease-out forwards;
}
.vote-container {
  display: flex;
  font-family: Play, Verdana, Geneva, Tahoma, sans-serif;
  flex-direction: column;
  width: 566px;
  margin-left: auto;
  margin-right: auto;
}

.vote-option {
  display: block;
  margin-bottom: 20px; /* Adjust as necessary */
  position: relative; /* Allows absolute positioning within */
  max-height: 73px;
  transition: transform 1.25s ease-in-out, opacity 1s ease-in-out;
}

.winner-anim {
  transform: translateY(-6px);
  /* color: red; */
}

.loser-anim {
  opacity: 0.18;
}

.vote-bar-empty {
  width: 566px;
  height: 73px;
  min-height: 73px;
  background-color: #ddd; /* Light grey to represent the empty part of the bar */
  height: 30px; /* Adjust height as necessary */
  border-radius: 5px; /* Optional: rounds corners of the bar */
}

.vote-bar {
  height: 73px;
  /* background-color: blue; */
  background-color: grey;
  text-align: right;
  transition: width 0.5s ease-in-out;
  border-radius: 5px; /* Optional: rounds corners of the bar */
}

.vote-text {
  /* position: absolute; */
  left: 0; /* Aligns the text to the far right */
  text-align: center;
  /* top: 50%; */
  transform: translateY(-50px);
  margin-left: 10px;
  padding-right: 10px; /* Ensures padding from the right edge */
}


body {
  background-color: #fff;
  /* height: 100vh; */
}

.welcome-wrapper {
  height: 100vh;
}

.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  margin: auto 0 auto 0;
}

.text-box {
  max-height: 150px;
  overflow: visible;
  margin-bottom: 4px;
}

h1 {
  font-size:2em;

  margin-bottom: 0px;
  margin-top: 0px;
}
.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.title-image {
    display: flex;
    margin: auto;
    /* margin-top: 7%; */
    /* border-radius: 10px; */
}

.title-image img {
    display: flex;
    /* filter: grayscale(75%); */
    filter: grayscale(60%);
    border-radius: 10px;
    box-shadow: 100px;
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 100 100" xml:space="preserve"><style type="text/css">.blur{filter:url(%23softedge);}</style><filter id="softedge"><feGaussianBlur stdDeviation="3.5"></feGaussianBlur></filter><g class="blur"><rect x="10" y="10" width="80" height="80"/></g></svg>'); 
	-webkit-mask-size: cover; 
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .fadeIn {
    animation: fadeIn 2s ease-out forwards;
  }
body {
  background-color: #fff;
  height: 100vh;
  text-align: center;
}

h1 {
  font-size:3em;
  max-width: 75vw;
  word-wrap: break-word;
  height: 100%;
  line-height: normal;
}

h2 {
  font-size:3em;
}

#root {
  display: flex;
  height: 100vh;
}

.goodbye-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height:100vh;
  margin: auto 0 auto 0;
}

.goodbye-text {
  display: flex;
  flex-direction: column;
}

#takk {
  margin-bottom: 34px;
}
.App {
    height: 100vh;
    display: flex;
}

.fullscreen-btn {
    --color: #a5a5b0;
    width: 40px;
    height: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    /* color: rgb(35, 35, 35); */
    cursor: pointer;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
  }
  
  .fullscreen-btn svg {
    height: 100%;
    fill: #c9c9c9;
  }
  
  .fullscreen-btn:hover {
    width: 45px;
    height: 45px;
    overflow: visible;
  }

  .tooltip {
    position: absolute;
    top: -45px;
    /* background-color: rgba(0, 0, 0, 0.753); */
    background-color: rgba(85, 85, 85, 0.753);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8em;
    transition: all 0.3s;
    opacity: 0;
  }

  .fullscreen-btn:hover .tooltip {
    transform: translateY(2.5px);
    opacity: 1;
  }
  

.playbutton {
  --color: #a5a5b0;
  --size: 30px;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: var(--size);
  -webkit-user-select: none;
          user-select: none;
  fill: #c9c9c9;
  animation: keyframes-fill .5s;
}

