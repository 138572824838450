.App {
    height: 100vh;
    display: flex;
}

.fullscreen-btn {
    --color: #a5a5b0;
    width: 40px;
    height: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    /* color: rgb(35, 35, 35); */
    cursor: pointer;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
  }
  
  .fullscreen-btn svg {
    height: 100%;
    fill: #c9c9c9;
  }
  
  .fullscreen-btn:hover {
    width: 45px;
    height: 45px;
    overflow: visible;
  }

  .tooltip {
    position: absolute;
    top: -45px;
    /* background-color: rgba(0, 0, 0, 0.753); */
    background-color: rgba(85, 85, 85, 0.753);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8em;
    transition: all 0.3s;
    opacity: 0;
  }

  .fullscreen-btn:hover .tooltip {
    transform: translateY(2.5px);
    opacity: 1;
  }
  

.playbutton {
  --color: #a5a5b0;
  --size: 30px;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: var(--size);
  user-select: none;
  fill: #c9c9c9;
  animation: keyframes-fill .5s;
}
