body {
  background-color: #fff;
  /* height: 100vh; */
}

.welcome-wrapper {
  height: 100vh;
}

.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  margin: auto 0 auto 0;
}

.text-box {
  max-height: 150px;
  overflow: visible;
  margin-bottom: 4px;
}

h1 {
  font-size:2em;

  margin-bottom: 0px;
  margin-top: 0px;
}