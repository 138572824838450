.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.title-image {
    display: flex;
    margin: auto;
    /* margin-top: 7%; */
    /* border-radius: 10px; */
}

.title-image img {
    display: flex;
    /* filter: grayscale(75%); */
    filter: grayscale(60%);
    border-radius: 10px;
    box-shadow: 100px;
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 100 100" xml:space="preserve"><style type="text/css">.blur{filter:url(%23softedge);}</style><filter id="softedge"><feGaussianBlur stdDeviation="3.5"></feGaussianBlur></filter><g class="blur"><rect x="10" y="10" width="80" height="80"/></g></svg>'); 
	-webkit-mask-size: cover; 
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .fadeIn {
    animation: fadeIn 2s ease-out forwards;
  }