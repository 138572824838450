.vote-container {
  display: flex;
  font-family: Play, Verdana, Geneva, Tahoma, sans-serif;
  flex-direction: column;
  width: 566px;
  margin-left: auto;
  margin-right: auto;
}

.vote-option {
  display: block;
  margin-bottom: 20px; /* Adjust as necessary */
  position: relative; /* Allows absolute positioning within */
  max-height: 73px;
  transition: transform 1.25s ease-in-out, opacity 1s ease-in-out;
}

.winner-anim {
  transform: translateY(-6px);
  /* color: red; */
}

.loser-anim {
  opacity: 0.18;
}

.vote-bar-empty {
  width: 566px;
  height: 73px;
  min-height: 73px;
  background-color: #ddd; /* Light grey to represent the empty part of the bar */
  height: 30px; /* Adjust height as necessary */
  border-radius: 5px; /* Optional: rounds corners of the bar */
}

.vote-bar {
  height: 73px;
  /* background-color: blue; */
  background-color: grey;
  text-align: right;
  transition: width 0.5s ease-in-out;
  border-radius: 5px; /* Optional: rounds corners of the bar */
}

.vote-text {
  /* position: absolute; */
  left: 0; /* Aligns the text to the far right */
  text-align: center;
  /* top: 50%; */
  transform: translateY(-50px);
  margin-left: 10px;
  padding-right: 10px; /* Ensures padding from the right edge */
}

