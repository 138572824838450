body {
  background-color: #fff;
  height: 100vh;
  text-align: center;
}

h1 {
  font-size:3em;
  max-width: 75vw;
  word-wrap: break-word;
  height: 100%;
  line-height: normal;
}

h2 {
  font-size:3em;
}

#root {
  display: flex;
  height: 100vh;
}

.goodbye-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height:100vh;
  margin: auto 0 auto 0;
}

.goodbye-text {
  display: flex;
  flex-direction: column;
}

#takk {
  margin-bottom: 34px;
}